<template>
    <div v-if="!isLoadingCompany && !isLoadingQuest">
        <!-- Uncomment when we want to bring back form-builder.
        <FormBuilderApp
            :offsetLeft="248"
            :offsetTop="280"
            :company="company"
            :questionnaire="questionnaire"
            @goBack="goBack"
        />-->
    </div>
</template>

<script setup>
// import { FormBuilderApp } from "form-builder";
import { ref } from "vue";
import "@mdi/font/css/materialdesignicons.css";
import { useRoute, useStore, useRouter } from "@/helpers/composition-helper";
import { useQuery } from "@vue/apollo-composable";
import cloneDeep from "lodash.clonedeep";
import getCompany from "../graphql/overview/getCompany.gql";
import getQuestionnaire from "../graphql/getQuestionnaire.gql";

const route = useRoute();
const router = useRouter();
const store = useStore();

const company = ref({});
const questionnaire = ref({});
const isLoadingCompany = ref(true);
const isLoadingQuest = ref(true);

function loadData() {
    // load company
    const {
        result: companyResult,
        onResult: onCompanyResult,
        onError: onCompanyError,
        loading: companyLoading,
    } = useQuery(getCompany, {
        id: route.params.companyId,
    });
    isLoadingCompany.value = companyLoading;
    if (companyResult.value) company.value = cloneDeep(companyResult.value.questionnaire);
    onCompanyResult((res) => {
        company.value = cloneDeep(res.data.company);
    });
    onCompanyError(() => {
        store.dispatch("pushNotification", {
            type: "error",
            title: "Error",
            message: "The form builder could not be loaded properly [company err].",
        });
    });

    // load questionnaire
    const {
        result: questResult,
        onResult: onQuestResult,
        onError: onQuestError,
        loading: questLoading,
    } = useQuery(getQuestionnaire, {
        id: route.params.questId,
    });
    isLoadingQuest.value = questLoading;
    if (questResult.value) questionnaire.value = cloneDeep(questResult.value.questionnaire);
    onQuestResult((res) => {
        questionnaire.value = cloneDeep(res.data.questionnaire);
    });
    onQuestError(() => {
        store.dispatch("pushNotification", {
            type: "error",
            title: "Error",
            message: "The form builder could not be loaded properly [quest err].",
        });
    });
}

/* eslint-disable no-unused-vars */
function goBack() {
    router.push(`/company/${route.params.companyId}?activeTab=questionnaires`);
}

loadData();
</script>
